<template>
<Layout style="height: 100%">
    <Content class="content" style="height: 100%">
        <GeminiScrollbar style="height:100%;overflow: auto;">
        <Form ref="dataForm" :model="formData" :rules="rules" label-position="top">
            <FormItem prop="channelId" style="position: relative;">
                <div style="display: flex;color: #0077EE;font-weight: bolder;">
                    <span style="margin:0 20px 0 12px;font-size: 16px;">渠道选择</span>
                    <CheckboxGroup v-model="formData.channelId" @on-change="checkChannel">
                        <Checkbox v-for="(item, index) in channelList" :key="index" :label="item.id">{{item.text}}</Checkbox>
                    </CheckboxGroup>
                </div>
                <div v-if="remark" style="color: red;position: absolute;top: 32px;left: 274px;line-height: 24px;">{{remark}}</div>
            </FormItem>
            <!-- <FormItem v-if="title == true" class="formIteminput" label="标题" prop="title">
                <Input v-model="formData.title"/>
            </FormItem> -->
            <div v-if="title == true" style="margin-bottom: 24px;">
                <div style="display: flex;padding-left: 10px;">
                    <div style="width: 64px;text-align-last: justify;margin-right: 20px;">标题</div>
                    <Input v-model="formData.title" style="width: 200px;"/>
                </div>
                <div style="padding: 5px 0 0 94px;display: flex;">
                    <Upload style="display: flex;" 
                    action="/api/respond/resource/upload"
                    :headers='{Authorization: currentToken}'
                    :on-success="handleSuccess" 
                    :on-error="handleError"
                    :on-format-error="handleFormatError"
                    :on-remove="handleRemove"
                    :show-upload-list='true'
                    :format="['doc','docx']">
                        <Button type="info" style="margin-right: 10px;background-color:#26A57D;">添加附件</Button>
                    </Upload>
                </div>
            </div>
            <FormItem class="formItemWidth" label="发布内容" prop="content">
                <Input v-model="formData.content" type="textarea" :autosize="{minRows: 3,maxRows: 3}"/>
            </FormItem>
            <FormItem>
                <!-- <Input v-model="formData.personIds" type="textarea" style="display:none;"/> -->
                <div style="display: flex;">
                    <span style="margin:0 20px 0 12px;font-size: 16px;">人员选择</span>
                    <memberSelect @checkedMembers='checkedMembers' v-if="areaList.length>0 && roleList.length>0" :roleList='roleList' :areaList='areaList' :checkedMemberData='checkedMember'></memberSelect>
                </div>
            </FormItem>
            <!-- <Table class="table-list" height="250" :loading="loading" :columns="columns" :data="data" @on-selection-change='checkedMember' no-data-text="没有符合查询条件的数据">
                <template slot-scope="{ row }" slot="roleId">
                    {{ getRoleName(row.roleId) }}
                </template>
                <template slot-scope="{ row }" slot="areaCode">
                    {{ getAreaName(row.areaCode) }}
                </template>
            </Table> -->
            <FormItem>
                <Button style="margin-left: 100px;background: #00A0E9;" type="primary" @click="submit()">发布</Button>
            </FormItem>
        </Form>
        </GeminiScrollbar>
    </Content>
</Layout>
</template>
<script>
import Vue from 'vue'
import request from '@/utils/request'
// import memberSelect from './member_select.vue'
import memberSelect from '@/views/sys_manage/threshold_manage/threshold_setting/member_select.vue'
export default Vue.extend({
    components: {
        memberSelect,
    },
    data() {
        return {
            channelList: [],
            thresholdId: '',
            rowData: {},
            areaList: [],
            roleList: [],
            checkedMember: [],
            formData: {},
            pushModes: [],
            rules: {
                content: [{ required: true, message: '发布内容不能为空', trigger: 'blur' }],
            },
            remark: '',
            title: false,
            currentToken: this.$store.state.token,
            successFileList: [],
            removeFileList: [],
        }
    },
    mounted() {
        // console.log(this.$route.query)
        // console.log(this.$route.query.attachmentJson)
        const id = this.$route.query.id
        this.formData.attachmentJson = this.$route.query.attachmentJson
        if (this.formData.attachmentJson) {
            this.title = true
            this.formData.title = this.$route.query.name
        }
        this.rowData = this.$route.params
        this.thresholdId = this.rowData.thresholdId
        this.formData.forewarningId = this.rowData.id
        Promise.all([
            this.getChannelList(),
            this.getContent(id),
            this.getAreaList(),
            this.getPublishRole(),
            this.getCheckedMember(),
        ]).then((e) => {
            this.getData()
        })
    },
    methods: {
        handleSuccess(response, file, fileList) {
            // console.log(response)
            // console.log(file)
            // console.log(fileList)
            this.successFileList = fileList
            this.$Message.success('上传完成')
        },
        handleError(res) {
            console.log(res)
            this.$Message.error('上传失败，请重试')
        },
        handleFormatError(file) {
            this.$Notice.warning({
                title: '文件格式有误',
                desc: '文件' + file.name + '格式有误, 请选择doc或docx格式文件',
            });
        },
        handleRemove(file, fileList) {
            // console.log(file)
            // console.log(fileList)
        },
        // 获取渠道
        getChannelList() {
            return request.get('/api/respond/push_mode/supported_list').then((data) => {
                // console.log(data)
                this.channelList = data
            })
        },
        // 获取产品 -- 发布内容
        getContent(id) {
            if (id) {
                // /api/sp/product/content/产品ID -- 产品
                // /api/sp/product/find/产品ID -- 附件
                return request.get(`/api/sp/product/content/${id}`).then((data) => {
                    // console.log(data)
                    this.$set(this.formData, 'content', data)
                })
            }
        },
        checkChannel(data) {
            if (data.indexOf(5) === -1) {
                this.remark = ''
            } else {
                this.remark = this.channelList[3].remark
            }
            if (!this.formData.attachmentJson) {
                if (data.indexOf(4) === -1) {
                    this.title = false
                } else {
                    this.title = true
                }
            }
            this.pushModes = data
            this.formData.channelId = this.pushModes
            // console.log(this.pushModes)
            // console.log(this.formData.channelId)
        },
        getAreaList() {
            return request.get('/api/sys/area/descendants', null)
            .then((data) => {
                const {current} = this.$store.state
                this.areaList = [...[{id: current.areaId, name: current.areaName}], ...data]
            })
        },
        getPublishRole() {
            return request.get('/api/respond/respond_role/list', null)
            .then((data) => {
                this.roleList = data
            })
        },
        getCheckedMember() {
            if (!this.thresholdId) {
                return
            }
            return request.get(`/api/respond/respond_person/by_threshold_id/${this.thresholdId}`)
            .then((data) => {
                this.checkedMember = data
                this.formData.personIds = this.checkedMember.map((e) => e.id)
            })
        },
        checkedMembers(value) {
            this.formData.personIds = value.map((e) => e.id)
        },
        getData() {
            if (!this.thresholdId) {
                return
            }
            request.get(`/api/respond/threshold/${this.thresholdId}`)
            .then((data) => {
                this.formData = {...this.formData, ...data}
                this.formData.content = this.rowData.content
            }).catch((error) => {
                this.formData.content = this.rowData.content
            })
        },
        submit() {
            const {forewarningId, content, personIds, attachmentJson, title} = this.formData
            const { successFileList } = this
            const attachmentNew = []
            successFileList.map((e) => {
                const arr = e.response.split('/')
                attachmentNew.push({
                    filename: arr[arr.length - 1],
                    path: e.response,
                })
            })
            if (attachmentJson) {
                attachmentNew.push(attachmentJson[0])
            }
            // console.log(attachmentNew)
            if (this.pushModes.length === 1 && this.pushModes[0] === 5) {
                // console.log('微信')
            } else {
                if (!personIds || personIds.length === 0) {
                    this.$Message.warning('人员不能为空!')
                    return
                }
            }
            const params = {
                forewarningId,
                content,
                personIds,
                pushModes: this.pushModes,
                attachmentJson: JSON.stringify(attachmentNew),
                title,
            }
            // console.log(params)
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    // console.log(params)
                    request.post(`/api/respond/post_message`, params)
                    .then((res) => {
                        // this.getWarnNumber()
                        this.$Message.success('发布成功!')
                        this.$store.commit('setCallIndex', 3)
                        this.$router.replace('callRecond')
                    }).catch((error) => {
                        this.$Message.warning(error)
                    })
                }
            })
        },
        // getWarnNumber() {
        //     request.get('/api/respond/forewarning/count_by_zero', null)
        //     .then((data) => {
        //         this.$store.commit('setWarnNum', data)
        //     })
        // },
    },
})
</script>
<style scoped>
::v-deep .ivu-upload-list{
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
}
.ivu-layout{
    background: transparent;
    color: #0077EE;
}
.formItemWidth{
    display: flex;
}
.ivu-checkbox-wrapper {
font-size: 16px;
}
::v-deep .ivu-btn>span{
font-size: 16px;
}
::v-deep .formItemWidth .ivu-form-item-label {
    margin-right: 20px;
    font-size: 16px;
}
::v-deep .formItemWidth .ivu-form-item-content{
    width: 92%;
    /* height: 200px; */
}
.formIteminput{
    display: flex;
}
::v-deep .formIteminput .ivu-form-item-label {
    margin: 0 20px 0 10px;
    font-size: 16px;
}
::v-deep .ivu-input{
    color: #0077EE;
    background: transparent;
    border-color: #0077EE;
}
::v-deep .ivu-form .ivu-form-item-label{
    color: #0077EE;
}
::v-deep .ivu-tree-title {
    color: #0077EE;
}
</style>